import React from 'react';
import Layout from '../components/Layout';
import AboutTemplate from '../templates/AboutTemplate';

const dummyData = {
	masthead: {
		title: 'About Us',
		image: '/img/SpergelCorporate_About_Masthead.jpg',
	},
	side_left: {
		image: '/img/SpergelCorporate_About_OurMission_Img.jpg',
		image_alt: 'Hands',
		is_left: true,
		side_content:
			'## Strategic Solutions\n\nThe Spergel Strategic Solutions approach to business consulting services will cover all of your restructuring needs. Get cost-effective debt help for your small business today.',
		main_content:
			'## Let Us Help You Get Back To Running Your Business\n\nAt Spergel, we have spent the last 30 years helping business owners solve their debt problems. Whether you own a small business or a large business, we pride ourselves on the affordable debt help we provide to our clients. Our trustees spend every day creating solutions that put our clients first.',
	},
	side_right: {
		image: '/img/SpergelCorporate_GivingBack_Img.png',
		image_alt: 'Hands',
		is_left: false,
		main_content:
			'## Spergel Invests In Our Communities\n\nSpergel Licensed Insolvency Trustees care about our clients and our staff. We also care about each community that we work in. Whether it’s getting creative to assist small businesses who cannot afford our services or supporting non-profit community organizations – we believe in making meaningful contributions. We’ve got over 30 years of experience making a difference in the lives of the people within our communities – and we do it with strategic solutions and strategic support.',
	},
	industry_tiles: {
		industries: [
			{
				name: 'Agriculture',
				image: '/img/SpergelCorporate_Home_Agriculture.jpg',
			},
			{
				name: 'Distribution',
				image: '/img/SpergelCorporate_Home_Distribution.jpg',
			},
			{
				name: 'Technology',
				image: '/img/SpergelCorporate_Home_Technology.jpg',
			},
			{
				name: 'Real Estate',
				image: '/img/SpergelCorporate_Home_RealEstate.jpg',
			},
			{
				name: 'Construction',
				image: '/img/SpergelCorporate_Home_Construction.jpg',
			},
			{
				name: 'Healthcare',
				image: '/img/SpergelCorporate_Home_Healthcare.jpg',
			},
			{
				name: 'Manufacturing',
				image: '/img/SpergelCorporate_Home_Manufacturing.jpg',
			},
			{
				name: 'Retail',
				image: '/img/SpergelCorporate_Home_Retail.jpg',
			},
		],
	},
};

const AboutPage = () => {
	return (
		<Layout>
			<AboutTemplate data={dummyData} />
		</Layout>
	);
};

export default AboutPage;
