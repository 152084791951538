import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { PageWrap } from '../styles/common';
import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';
import SideImageInfoSection from '../components/SideImageInfoSection';
import ArrowLink from '../components/ArrowLink';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';

export default ({ data }) => {
	return (
		<PageWrap>
			<Helmet>
				<title>About | Spergel Corporate</title>
			</Helmet>
			<Masthead title={data.masthead.title} image={data.masthead.image} />
			<SideImageInfoSection
				image={data.side_left.image}
				imageAlt={data.side_left.image_alt}
				isLeft={data.side_left.is_left}
				sideContent={data.side_left.side_content}
				mainContent={data.side_left.main_content}
			/>
			<Network>
				<div className="container" style={{ padding: '0 2rem' }}>
					<h2 style={{ textAlign: 'center' }}>Our Network</h2>
					<div className="columns">
						<div className="column is-8">
							<p>
								Our strategic partners help with seamless business solutions to
								solve your business debt problem. Our Trustees have a long
								history of working in an advisory capacity to assist with
								business solutions for the clients of accountants, lawyers, and
								investors alike. As a result, we also have a large national (and
								international) network of stakeholders who can help when needed,
								providing access to lenders, lawyers, tax representatives and
								specialists.
							</p>
							<p>
								We are also founding members of the{' '}
								<a
									href="http://www.icin.ca"
									target="_blank"
									rel="noopener noreferrer"
								>
									ICIN
								</a>{' '}
								(Independent Canadian Insolvency Network), and GRIP (Global
								Restructuring Insolvency Professionals) connecting Insolvency
								firms across Canada and Europe.
							</p>
						</div>
						<div className="column is-4">
							<div style={{ textAlign: 'center' }}>
								<img
									src="/img/SpergelCorporate_About_GripLogo_Colour.svg"
									alt="GRIP Logo"
								/>
							</div>
						</div>
					</div>
				</div>
			</Network>
			<Certifications>
				<div className="container">
					<h2>Certifications</h2>
					<p>
						Spergel Licensed Insolvency Trustees (LIT) are certified
						restructuring professionals, licensed by the Office of the
						Superintendent of Bankruptcy. In addition to government
						certification in insolvency matters, many of our Trustees are also
						Chartered Professional Accountants with post-secondary backgrounds
						in business, finance, and/or commerce. We also have several Licensed
						Insolvency Trustees who possess CFE (Certified Fraud Examiner)
						designations.
					</p>
					<div className="center">
						<ArrowLink href="/team">FIND AN EXPERT</ArrowLink>
					</div>
				</div>
			</Certifications>
			<SideImageInfoSection
				image={data.side_right.image}
				imageAlt={data.side_right.image_alt}
				isLeft={data.side_right.is_left}
				mainContent={data.side_right.main_content}
				bg={'#f2f2f2'}
			/>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const Network = styled.div`
	background-image: url('/img/SpergelCorporate_About_OurNetwork_Img.jpg');
	background-size: cover;
	background-position: bottom center;
	padding: 4rem 0 10rem;
	a {
		color: inherit;
	}
	img {
		width: 100%;
		max-width: 300px;
	}
	h2 {
		text-align: center;
		font-size: 2.5rem;
		font-weight: 400;
	}
`;

const Certifications = styled.div`
	h2 {
		text-align: center;
		font-size: 2.5rem;
		font-weight: 400;
	}
	.center {
		text-align: center;
		padding: 2rem 0;
	}
`;
